.not-found {
  padding-bottom: 100px;
  text-align: center;
  .pic {
    display: block;
    width: 100%;
    margin: 100px auto;
  }
  .tip {
    margin-bottom: 40px;
    font-size: 36px;
    text-align: center;
  }
  .am-button {
    width: 50%;
    margin: 40px auto;
  }
}
